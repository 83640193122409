<template>
  <div class="container">

    <div class="header" style="z-index: 2;">

      <div class="title1">ハル</div>
      <div class="title2">はワクチンに関するあなたの疑問に何でもお答えします。あなた専用の相談相手としてご活用ください。</div>
    </div>


    <div class="main">

      <div v-if="!topicId">

        <!-- <img class="logo" :src="require('@/assets/mobile/bg-1.png')" alt="" /> -->

        <div class="logo1"></div>

        <div style="margin-bottom: 8.5rem;" class="flex flex-column align-center">
          <div class="text">あなた専用のワクチン相談相手との</div>
          <div class="confirm" @click="startChat">チャットをはじめる
          </div>
        </div>

      </div>


      <div v-else class="used">

        <!-- <img :src="require('@/assets/mobile/bg-1.png')" alt="" /> -->

        <div class="logo2"></div>

        <div class="text">
          <div class="desc">最後に終了したところから、チャットボットとの会話を再開しますか？</div>

          <div class="flex flex-column align-center" style="align-items: end;">

            <div @click="restart" class="button"
              style="background-color: rgba(0, 0, 0, 1);color: white;margin-bottom: 1rem;">新しい会話を始める</div>
            <div @click="toChat" class="button" style="background-color: white;color: black;">再開する</div>
          </div>
        </div>


      </div>


      <div style="width: 100%;margin-top:3rem" class="flex justify-center logo-list">

        <img :src="require('@/assets/mobile/logo-1.png')" @click="toPage('https://www.d24h.hk/')" alt="" srcset="">
        <img style="margin: 0 1.5rem;" :src="require('@/assets/mobile/logo-2.png')"
          @click="toPage('https://www.vaccineconfidence.org/')" alt="" srcset="">
        <img :src="require('@/assets/mobile/logo-3.png')" @click="toPage('https://www.fudan.edu.cn/')" alt="" srcset="">


      </div>
    </div>


    <div>


    </div>


    <a-spin class="spin" :spinning="spinning" v-if="spinning" />





    <agreement-modal ref="agreement" />
  </div>
</template>
<script>


import { getAction, postAction } from '@/request/http.js'
import agreementModal from './agreement.vue'


export default {
  data() {
    return {
      botType: "A",
      nickname: "",
      spinning: false,
      topicId: '',
      otherBotConfirm: false
    }
  },
  mounted() {

    this.nickname = localStorage.getItem('nickname') || 'user'
    this.botType = localStorage.getItem('botType') || 'A'
    this.topicId = localStorage.getItem('topicId') || ''



  },
  methods: {

    setRootFontSize() {
      const viewportWidth = window.innerWidth;
      // 根据页面宽度动态计算根元素的字体大小
      const newFontSize = viewportWidth / 37.5; // 1rem 等于页面宽度的 1%
      // 设置根元素的字体大小
      document.documentElement.style.fontSize = `${newFontSize}px`;
    },

    startChat() {

      if (!this.botType) {
        this.$message.warning('タイプを選択してください')
        return false
      }

      if (!this.nickname) {
        this.$message.warning('ニックネームを入力してください')
        return false
      }
      let payload = {

        nickname: this.nickname,
        browser_id: localStorage.getItem('browser_id')
      }

      this.spinning = true
      postAction('/record/topic', payload).then(res => {
        this.spinning = false;
        if (res.code == 0) {
          localStorage.setItem('topicId', res.data)
          localStorage.setItem('nickname', payload.nickname)
          localStorage.setItem('botType', this.botType)


          this.$refs.agreement.visible = true;


        } else {
          this.$message.warning(res?.msg)
        }

      })

    },




    toChat() {
      if (!localStorage.getItem('question')) {
        this.$refs.agreement.visible = true;
      } else {
        this.$router.push('/chat')
      }
    },

    restart() {
      localStorage.removeItem('topicId')
      localStorage.removeItem('question')
      localStorage.removeItem('nickname')
      localStorage.removeItem('botList')
      localStorage.removeItem('botType')
      location.reload()
    },


    toPage(e) {
      window.open(e)
    },





  },
  created() {

  },
  components: {
    agreementModal
  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  /* background: white; */
  background: rgba(45, 30, 142, 1);

  /* display: flex; */
  /* background-image: url('../../../assets/mobile/background.png'); */
  background-size: 100% 100%;
}

.title {
  width: 100%;
  background-image: url('../../../assets/mobile/title.png');
  background-size: 100% 100%;
}

.bot-type {

  width: 13rem;
  height: 13rem;
  position: relative;
}

.bot-type .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 2rem;
}

.bot-select-shadow1 {
  transition: transform 0.5s linear;
  transform: rotate(10deg);
}

.bot-select-shadow2 {
  transition: transform 0.5s linear;
  transform: rotate(-10deg);
}

.bot-info {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.img-container {
  position: relative;
  width: 100%;
  height: 50%;
}

.bot-img {
  position: absolute;
  width: 60%;
  bottom: 0;
}

.bot-type-title {
  font-size: 1.3rem;
  color: black;
  font-weight: 400;
  letter-spacing: 0.1rem;
}

.bot-type-desc {
  font-size: 1rem;
  background: rgb(167 167 167 / 22%);
  padding: 0.2rem 0.8rem;
  border-radius: 1rem;
}

.text {
  color: #ffffffc4;
  font-size: 1.6rem;
  letter-spacing: 1px;
  margin: 0.1rem 0;
}

.nickname {
  width: 100%;
  margin-top: 3rem;
  height: 5rem;
  border-radius: 3rem;
  font-size: 1.6rem;
  padding-left: 2rem;
  border: none;
}

.confirm {
  margin-top: 0.5rem;
  width: 61%;
  line-height: 4.2rem;
  height: 4.2rem;
  border-radius: 3rem;
  color: black;
  background: white;
  letter-spacing: 1px;
  font-family: Inter;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;

}

.sure {
  background: linear-gradient(#f8cbad, #fe5f31);
  padding: 1.2rem 3rem;
  border-radius: 3rem;
  color: white;
  font-size: 1.5rem;
  word-break: keep-all;
  margin: 0 1rem;
}

.continue {
  background: linear-gradient(#ffd388, #ff8632);
  padding: 1.2rem 3rem;
  border-radius: 3rem;
  color: white;
  font-size: 1.5rem;
  word-break: keep-all;
  margin: 0 1rem;
}

.logo1 {
  width: 40rem;
  height: 40rem;
  background: rgba(208, 23, 23, 1);
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  top: -28rem;
  right: -14rem;
}

.logo2 {
  width: 40rem;
  height: 40rem;
  background: rgba(208, 23, 23, 1);
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  top: -12rem;
  left: -11rem;
}

.header {
  width: 90%;
  padding: 10rem 3rem;
  color: white;
  z-index: 2;
  position: relative;
}

.title1 {
  font-family: Inter;
  font-size: 5rem;
  font-weight: 700;
  line-height: 60.51px;
  text-align: left;
  color: white;
}

.title2 {
  font-family: Inter;
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 3rem;
  text-align: left;
  margin-top: 1rem;

}

img.logo {
  position: fixed;
  bottom: 0;
  right: -11rem;
  height: 70vh;
  z-index: -1;
}

.logo-list img {
  background: white;
  padding: 0.6rem;
  border-radius: 3rem;
  width: 7.5rem;
  height: 7.5rem;
}

.main {
  width: 100%;
  padding: 0 2rem;
  position: fixed;
  position: fixed;
  bottom: 3rem;
}

.used {
  /* background: rgb(92, 77, 202); */
  width: 100%;
  height: 37vh;
  margin-bottom: 6rem;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1); */
  border-radius: 5rem;
  position: relative;
}

.used img {
  width: 17rem;
  transform: scaleX(-1);
  position: absolute;
  bottom: 0;
  left: -1rem;
}

.used .text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.used .text .desc {
  font-family: Inter;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.4rem;
  text-align: left;
  width: 55%;
  margin-left: 5rem;

}

.text div.button {


  width: 17rem;
  text-align: center;
  height: 4rem;
  border-radius: 3rem;
  background: white;
  font-size: 1.4rem;
  line-height: 4rem;
}
</style>