<template>
  <a-modal :visible="visible" :confirm-loading="confirmLoading" width="30%" :footer="false" v-if="visible"
    class="container" :closable="false">



    <div class="content">

      <div class="title">こんにちは、私はハルトです</div>

      <div class="title2">最初に、以下の質問にお答えください</div>


      <div style="margin-bottom: 2rem;">

        <div class="item-title">質問１: あなたの携帯電話番号の下4桁を教えてください?</div>
        <input class="item-input" v-model="payload.user" />
      </div>


      <div style="margin-bottom: 2rem;">

        <div class="item-title">質問2: あなたの誕生月日を教えてください。（1月2日の場合、0102）</div>
        <input class="item-input" v-model="payload.mobile" />
      </div>


      <div style="margin-bottom: 2rem;">

        <div class="item-title">質問3: あなたの長女の誕生月日を教えてください。（1月2日の場合、0102）</div>
        <input class="item-input" v-model="payload.province" />
      </div>


      <div class="submit" @click="confirm">チャットをはじめる</div>
    </div>

    <a-spin class="spin" :spinning="spinning" v-if="spinning" />



  </a-modal>
</template>
<script>
import { getAction, putAction, postAction } from '@/request/http.js'

export default {
  data() {
    return {

      visible: false,
      spinning: false,
      payload: {
        user: "",
        mobile: "",
        province: undefined
      },
      confirmLoading: false,
      provinceList: []

    }
  },
  mounted() {
    // this.provinceList = p_list;
  },
  methods: {

    confirm() {

      if (!this.payload.user || !this.payload.mobile || !this.payload.province) {
        this.$message.warning('内容未输入完整')
        return false
      }



      // localStorage.setItem('userType', this.payload.user)
      let values = {
        answer_one: this.payload.user,
        answer_two: this.payload.mobile,
        answer_three: this.payload.province
      }
      values.session_id = localStorage.getItem('topicId')
      values.browser_id = localStorage.getItem('browser_id')
      postAction('answer/add', values).then(res => {
        if (res.code == 0) {
          this.$message.success('操作は成功しました');
          localStorage.setItem('question', 'yes')
          // this.$router.push('/chat')
          this.$router.push('/chat')
        } else {
          this.$message.warning(res.msg)
        }
      }).finally(() => {
        this.confirmLoading = false
      })
    },

    closeModal() {
      this.visible = false
    },
    inputChange(e) {
      console.log('e', e.target.value)
      let value = e.target.value
      this.payload.mobile = (value.match(/(\d{4}|\d+)/) || [''])[0]
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

  },
  computed: {

  }
}
</script>

<style scoped>
.container {
  width: 100%;
  position: relative;
  z-index: 1;
}

.container>>>.ant-modal {
  border-radius: 2.4rem;
  overflow: hidden;
}

.container>>>.ant-modal-body {
  padding: 0;
}

.header {
  position: relative;
}

.header .title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5rem 5rem;
}

.header .text {
  font-size: 1.6rem;
  font-weight: 500;
  color: #ffffff;
}

.content {
  width: 100%;
  position: relative;
  padding: 2rem 7.5rem;

}


.item {

  width: 100%;
  position: relative;
  background: white;
  margin-bottom: 1.6rem;
  border-radius: 0.5rem;

}

.item>>>.ant-select-selection--single {
  background: #f1f1f17a;
}

.item>>>.ant-input {
  background: #f1f1f17a;
}

.item-image {
  width: 6.3rem;
}

.item-question {
  height: calc(100% - 2.6rem);
  padding: 0.5rem 0;
}

.item-question-title {

  font-size: 1.3rem;
  font-weight: 400;
  color: #000000;
  margin-bottom: 0.5rem;
}

.action-button {
  width: 19.8rem;
  height: 5.8rem;
  text-align: center;
  line-height: 5.8rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 1.5rem;
  margin: 0 3rem;
}

.content>>>.ant-radio-button-wrapper {
  padding: 0 3rem;
  font-size: 1.6rem;
  font-weight: 400;
}

.required {
  position: absolute;
  margin-left: -0.5rem;
  top: -1rem;
  font-size: 2rem;
  color: red;
}
</style>

<style scoped>
.container{
  z-index: 100000;
  background: rgba(39, 26, 131, 1);
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 3.5rem;
  background: rgba(39, 26, 131, 1);
}



.title {
  font-family: Inter;
  font-size: 4.2rem;
  font-weight: 500;
  line-height: 5rem;
  text-align: center;
  color: white;
}

.title2 {
  font-family: Inter;
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 2.6rem;
  text-align: center;
  color: white;
  padding: 1rem 2rem;

}

.item-title {
  font-family: Inter;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-align: left;
  color: white;
}

.item-input {
  width: 100%;
  border: none;
  border-radius: 10rem;
  background:rgba(53, 39, 152, 1);
  height: 4.4rem;
  padding-left: 10px;
  margin-top: 1rem;
  color: white;
  font-size: 2rem;
}

.submit {
  width: 100%;
  height: 4.4rem;
  border-radius: 4rem;
  background: rgba(208, 23, 23, 1);
  font-family: Inter;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 4.4rem;
  text-align: center;
  color: white;
}

.desc {
  margin-top: 1rem;
  text-align: center;
  font-size: 1.2rem;
  font-family: PingFangSCRegular;
  font-weight: 400;
  color: #000000;
  line-height: 2rem;
}

.item {

  width: 100%;
  position: relative;
  background: white;
  margin-bottom: 1rem;
  border-radius: 0.5rem;

}

.item-image {
  width: 6.3rem;
}

.item-question {
  height: calc(100% - 2.6rem);
  padding: 1rem 1.5rem;
}

.item-question-title {

  font-size: 1.3rem;
  font-weight: 400;
  color: #000000;
  margin-bottom: 0.5rem;
}

.action-button {
  width: 15rem;
  height: 4.5rem;
  text-align: center;
  line-height: 4.5rem;
  border-radius: 3rem;

  color: white;
  font-size: 1.5rem;
}

.required {
  position: absolute;
  margin-left: -0.5rem;
  top: -0.5rem;
  font-size: 2rem;
  color: red;
}
</style>